import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import Slider from "react-slick";
import SEO from "../components/seo";
import { CheckCircle } from "react-feather";

const Heading = styled.h1`
  margin-bottom: 0.25em;
`;
const Subheading = styled.p`
  margin-bottom: 2em;
`;
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`;

const ServicesList = (props) => {
  let { slug, location, locationslug, serviceTitle } = props;
  return (
    <li>
      <Link to={"/" + slug + "-" + locationslug + "/"} title={serviceTitle}>
        {serviceTitle}
      </Link>
    </li>
  );
};

const CitiesServicesList = (props) => {
  let { location, slug, services, marketSegment = [] } = props;

  return (
    <li className="w-1/2 md:w-1/3 flex py-2">
      <div className="flex-shrink-0 mr-2">
        <CheckCircle fontWeight={900} strokeWidth={3} size={22} className="text-brand-500 mt-1" />
      </div>{" "}
      <span>
        <h3 className="text-gray-800 underline-none font-semibold font-display text-lg">{location}</h3>
        <ul>
          {services.edges.map((item, i) => {
            return marketSegment.map((marketSeg) => {
              if (
                ["kitchen-remodeling/", "kitchen-cabinets/", "bathroom-remodeling/"].includes(item.node.slug) &&
                marketSeg !== "sac"
              ) {
                return null;
              }
              let cleanReplaceServiceTitle = item.node.serviceTitle.replace(/{{city}}/g, location);
              return (
                <ServicesList
                  key={item.node.id}
                  locationslug={slug}
                  location={location}
                  serviceTitle={cleanReplaceServiceTitle}
                  slug={item.node.slug}
                />
              );
            });
          })}
        </ul>
      </span>
    </li>
  );
};

const ServiceArea = (props) => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              slug
              marketSegment
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
              content {
                id
                content
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout pageProps={props}>
          <SEO
            title={"Service Areas - Make It Simple Construction"}
            description={
              "This page contains a list of our service areas. If you do not see your city, please call us to confirm if you are in our service area."
            }
          />
          <div className="  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
            <h1 class="md:text-4xl font-semibold leading-10 text-3xl">Service Area</h1>
            <p class="text-gray-700 leading-6 max-w-3xl">
              We specialize in residential remodeling, offering comprehensive renovation, remodeling, and redesign
              services to clients in San Jose and neighboring areas. Learn more about our premium services in the
              following areas:
            </p>

            <div className="flex flex-wrap mt-4">
              {data.allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <CitiesServicesList
                    key={item.node.id}
                    location={item.node.location}
                    slug={item.node.slug}
                    marketSegment={item.node.marketSegment}
                    services={data.allContentfulServiceAreaContent}
                  />
                );
              })}
            </div>
          </div>
        </Layout>
      );
    }}
  />
);

export default ServiceArea;
